import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";


export default function ServicePage() {
    const { serviceId, servicelisting } = useParams();
    const [contactInfo, setContactInfo] = useState({})

    useEffect(() => {
        console.log(serviceId)
        console.log(servicelisting)

        fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_firebase_info`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                serviceId: serviceId,
                listingId: servicelisting
            })
        })
        .then(res => res.json())
        .then(res => {
            setContactInfo(res)
        })
    }, [])

    const downloadImage = () => {
        const link = document.createElement('a');
        link.href = contactInfo.photos[0];
        link.download = 'מזכרת מהאירוע.jpg'; // Default name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <section>
            <h1 dir="rtl">תודה שהשתתפתם במסיבה, מקווים לראותכם שוב</h1>

            <img src={contactInfo.photos ? contactInfo.photos[0] : ""} />

            <h1 dir="rtl">אל תשכחו להעלות לאינסטגרם ולתייג את @nissim_hen</h1>

            <button className="buttonCss" onClick={downloadImage} dir="rtl">
                הורידו את התמונה!
            </button>
        </section>
    )
}