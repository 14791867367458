import { useRef, useEffect, useState, useCallback, useContext } from 'react';
import { AttributionControl, FullscreenControl, GeolocateControl, Layer, Map, Marker, NavigationControl, ScaleControl, Source, useMap } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import SessionContext from './SessionContext';
// added the following 6 lines.
import mapboxgl from 'mapbox-gl';
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import MenuIcon from '@mui/icons-material/Menu';
import StraightIcon from '@mui/icons-material/Straight';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TourIcon from '@mui/icons-material/Tour';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import { indoorOuterWallsLayerOptions, parkingAvailableLayerOptions, parkingRouteLineLayerOptions, parkingRouteStrokeLayerOptions, parkingTakenLayerOptions, rasterLayerOptions } from '../helpers/layerOptions';
import GeneralMap from './GeneralMap';
import { MapContext } from 'react-map-gl/dist/esm/components/map';
import { bearing } from '@turf/turf'
import { IconButton, Input, InputAdornment, InputBase, List, ListItem, Paper, Stack, TextField } from '@mui/material';
import {QRCodeSVG} from 'qrcode.react';
import { Threebox } from 'threebox-plugin';
//To: 56.46021351,-2.97865631
//From: 56.45988393,-2.97819966

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function IndoorMap({ language = 'en', viewOptions }) {
    const mirrorInfo = useContext(SessionContext)
    const mapRef = useRef()

    const [routingMapGeojson, setRoutingMapGeojson] = useState(null)
    const [hoverInfo, setHoverInfo] = useState(null);

    const [indoorMapGeojson, setIndoorMapGeojson] = useState(null)
    const [multiFloorMapGeojson, setMultiFloorMapGeojson] = useState(null)

    const [currentFloor, setCurrentFloor] = useState(null)

    const [navigating, setNavigating] = useState(false)
    const [showDirections, setShowDirections] = useState(false)


    useEffect(() => {
        if (mirrorInfo && mirrorInfo._id) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_indoor_map`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mirror: {
                        token: mirrorInfo._id
                    }
                })
            })
            .then(res => res.json())
            .then(res => {
                setIndoorMapGeojson(res)
                //setParkingMapGeojson(res)
            })

            fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_multifloor_indoor_map`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mirror: {
                        token: mirrorInfo._id
                    }
                })
            })
            .then(res => res.json())
            .then(res => {
                console.log(res)
                setMultiFloorMapGeojson(res.maps)
                setCurrentFloor(res.maps[0].floor)
            })

            fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_indoor_nav`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mirror: {
                        token: mirrorInfo._id
                    },
                    startPosition: [56.45988393,-2.97819966],
                    endPosition: [56.46021351,-2.97865631]
                })
            })
            .then(res => res.json())
            .then(res => {
                setRoutingMapGeojson({
                    pathGeojson: res.pathGeojson, directions: combineDirections(res.directions).map(item => ({
                        ...item,
                        instruction: getInstructionTranslate(item.instruction)
                    }))
                })
                centerToNavigation({
                    pathGeojson: res.pathGeojson, directions: combineDirections(res.directions).map(item => ({
                        ...item,
                        instruction: getInstructionTranslate(item.instruction)
                    }))
                })
            })
            
        }
    }, [mirrorInfo])

    function getInstructionTranslate(instruction) {
        switch (instruction) {
            case 'Go straight': return "המשך קדימה"
            case 'Turn right': return 'פנה ימינה'
            case 'Turn left': return 'פנה שמאלה'
            case 'Arrive': return 'הגיע לחניה'
        }
    }

    function getDirectionIcon(instruction) {
        if (instruction.includes("קדימה")) {
            return <StraightIcon sx={{ fontSize: "40px" }} />
        }
        if (instruction.includes("ימינה")) {
            return <TurnRightIcon sx={{ fontSize: "40px" }} />
        }
        if (instruction.includes("שמאלה")) {
            return <TurnLeftIcon sx={{ fontSize: "40px" }} />
        }
        if (instruction.includes("הגיע")) {
            return <TourIcon sx={{ fontSize: "40px" }} />
        }
    }

    function combineDirections(instructions) {
        const combinedInstructions = [];
        let currentInstruction = null;
        let totalDistance = 0;

        for (const instruction of instructions) {
            if (instruction.instruction === "Go straight") {
                if (!currentInstruction) {
                    currentInstruction = instruction;
                } else {
                    currentInstruction.distance += instruction.distance;
                }
                totalDistance += instruction.distance;
            } else {
                if (currentInstruction) {
                    combinedInstructions.push(currentInstruction);
                    currentInstruction = null;
                }
                combinedInstructions.push(instruction);
            }
        }

        if (currentInstruction) {
            combinedInstructions.push(currentInstruction);
        }

        return combinedInstructions
    }

    function changeNavigatingState(map) {
        if(!navigating) {
            setNavigating(true)
            const flyToOptions = {
                center: routingMapGeojson.directions[0].source.coordinates,
                bearing: bearing(routingMapGeojson.directions[0].source.coordinates, routingMapGeojson.directions[0].target.coordinates),
                pitch: 45,
                zoom: 20.3,
                speed: 0.3,
                curve: 1,
                easing(t) {
                    return t;
                }
            }
            mapRef.current.flyTo(flyToOptions)

        } else {
            setNavigating(false)
            centerToNavigation(routingMapGeojson)
        }
    }

    function centerToNavigation(routingMap) {
        // Geographic coordinates of the LineString
        const coordinates = routingMap.pathGeojson.features[0].geometry.coordinates;

        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
            bounds.extend(coord);
        }
        mapRef.current.fitBounds(bounds, {
            padding: 20,
            bearing: 0,
            pitch: 45,
            speed: 0.3,
            curve: 1,
            easing(t) {
                return t;
            }
        });
    }

    function centerToPoint(options) {
        mapRef.current.flyTo(options)
    }

    function loadFunc() {
        const mapElem = mapRef?.current
        if (!mapElem) return

        console.log(mapElem)
        mapElem.on("style.load", (e) => {
            console.log(e);
          });
    }

    return (
        <GeneralMap language={language} viewOptions={viewOptions} mapRef={mapRef} onLoad={loadFunc}>
            <MapImage />
            <Source
                type="raster"
                name="parking_raster"
                id="parking_raster"
                url="mapbox://ofekk.79r8sfic"

            >
                <Layer {...rasterLayerOptions} />
            </Source>
            {
                multiFloorMapGeojson !== null && multiFloorMapGeojson.map(map => {
                    var baseHeight = map.type === "building" ? (parseInt(map.floor) - 1) * 3 : (parseInt(map.floor)) * -3
                    console.log(map.type === "building" ? (parseInt(map.floor) - 1) * 3 : (parseInt(map.floor)) * -3)
                    console.log(map.floor)
                    return (
                        <Source type="geojson" key={map.floor} data={map.layoutMap}>
                            <Layer {...{ //Building Walls
                                'type': 'fill-extrusion',
                                'minzoom': 19.0,
                                'paint': {
                                    'fill-extrusion-color': "#d4d7d8",
                                    'fill-extrusion-opacity': map.floor === currentFloor ? 1 : 0.1,
                                    'fill-extrusion-height':baseHeight + 3,
                                    'fill-extrusion-base': baseHeight,
                                },
                                'filter': ["all", ["==", "type", "wall"]]
                            }} />
                            <Layer {...{ //Flooring
                                'type': 'fill-extrusion',
                                'minzoom': 19.0,
                                'paint': {
                                    'fill-extrusion-color': "#aaa",
                                    'fill-extrusion-opacity': map.floor === currentFloor ? 1 : 0,
                                    'fill-extrusion-height': baseHeight + 0.01,
                                    'fill-extrusion-base': baseHeight
                                },
                                "layout": {
                                    
                                    'visibility': "visible"
                                },
                                'filter': ["all", ["==", "type", "building_outline"]]
                            }} />
                            <Layer {...{ //Service Names
                                'type': 'symbol',
                                'minzoom': 19.0,
                                'paint': {
                                },
                                'layout': {
                                    "text-field": ['get', 'name'],
                                    'visibility': map.floor === currentFloor ? "visible" : "none",
                                    'symbol-z-elevate': true,
                                    'symbol-z-order': 'auto'
                                },
                                'filter': ["any", ["==", "type", "room"], ["==", "type", "bathroom"]]
                            }} />
                        </Source>
                    )
                })
            }
            <Source type="geojson" data={indoorMapGeojson}>
                
                <Layer {...{
                    'type': 'fill-extrusion',
                    'minzoom': 20.0,
                    'paint': {
                        //'text-field': "name"
                        'fill-extrusion-color': "#55ff63",
                        'fill-extrusion-opacity': 0.3,
                        'fill-extrusion-height': 2,
                        'fill-extrusion-base': 0
                    },
                    'filter': ["any", ["==", "type", "room"], ["==", "type", "bathroom"]]
                }} />
                <Layer {...{
                    'type': 'fill-extrusion',
                    'minzoom': 20.0,
                    'paint': {
                        //'text-field': "name"
                        'fill-extrusion-color': "#ff6355",
                        'fill-extrusion-opacity': 0.8,
                        'fill-extrusion-height': 3,
                        'fill-extrusion-base': 0
                    },
                    'filter': ["any", ["==", "type", "door"]]
                }} />

                <Layer {...{
                    'type': 'fill-extrusion',
                    'minzoom': 20.0,
                    'paint': {
                        //'text-field': "name"
                        'fill-extrusion-color': "#6351ff",
                        'fill-extrusion-opacity': 0.3,
                        'fill-extrusion-height': 2,
                        'fill-extrusion-base': 0
                    },
                    'filter': ["any", ["==", "type", "elevator"], ["==", "type", "stairs"]]
                }} />
                <Layer {...{
                    'type': 'symbol',
                    'minzoom': 20.0,
                    'paint': {
                        //'text-field': "name"
                        'fill-extrusion-color': "#55ff63",
                        'fill-extrusion-opacity': 0.3,
                        'fill-extrusion-height': 5,
                        'fill-extrusion-base': 0
                    },
                    'layout': {
                        "text-field": ['get', 'type'],
                        'icon-image': 'stairs', // reference the image
                        'icon-size': 0.11,
                        'icon-anchor': "center",
                        'icon-offset': [0,-250]
                    },
                    'filter': ["any", ["==", "type", "elevator"], ["==", "type", "stairs"]]
                }} />
            </Source>

        
            <Source type="geojson" data={routingMapGeojson?.pathGeojson ? routingMapGeojson.pathGeojson : { type: 'FeatureCollection', features: [] }}>
                <Layer {...parkingRouteStrokeLayerOptions} />
                <Layer {...parkingRouteLineLayerOptions} />
            </Source>

            <Box className='directionsLeft' onClick={() => { setShowDirections(!showDirections) }}>
                <Typography sx={{ fontSize: "26px" }}>
                    <MenuIcon />
                </Typography>
            </Box>
            <Box className='directionsCenter'>
                <Paper
                    
                    component="form"
                    sx={{ display: 'flex', backgroundColor: "transparent", color: "white", boxShadow: "none", m: 0, p: 0}}
                >
                    <InputBase
                        sx={{ ml: 1, mr: 1, flex: 1, color: "white", fontSize: "22px" }}
                        placeholder="Search Places"
                        inputProps={{ 'aria-label': 'Search Places' }}
                    />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <ManageSearchIcon htmlColor='#fff' sx={{fontSize: "30px"}}/>
                    </IconButton>
                </Paper>
            </Box>
            <Box className='directionsRight' onClick={() => { changeNavigatingState() }}>
                {
                    !navigating &&
                    <Typography sx={{ fontSize: "26px", display: "flex", justifyContent: "center", alignItems: "center", columnGap: "5px" }}>
                        <PlayCircleFilledWhiteIcon sx={{ fontSize: "30px" }} />
                        Navigate
                    </Typography>
                }
                {
                    navigating &&
                    <Typography sx={{ fontSize: "26px", display: "flex", justifyContent: "center", alignItems: "center", columnGap: "5px" }}>
                        <StopCircleIcon sx={{ fontSize: "30px" }} />
                        Stop Nav
                    </Typography>
                }
            </Box>
            
            {routingMapGeojson !== null && showDirections &&
                <Box className='directionsLeft directionsLeftSet' sx={{height: "auto"}}>
                    {
                        routingMapGeojson.directions.map((direction, i) => (
                            <div key={i} className='directionGrid'>
                                <Box dir='rtl' sx={{ gridArea: "1 / 1 / 3 / 2", display: "flex" }}>{getDirectionIcon(direction.instruction)}</Box>
                                <Box dir='rtl' sx={{ gridArea: "1 / 2 / 2 / 3" }}><Typography>{Math.ceil(direction.distance)} מטרים</Typography></Box>
                                <Box dir='rtl' sx={{ gridArea: "2 / 2 / 3 / 3" }}><Typography>{direction.instruction}</Typography></Box>
                            </div>
                        ))
                    }
                </Box>
            }
            {
                (mirrorInfo.isMirror || mirrorInfo.isMirror === "true") &&
                <Box className='scan-qr'>
                    <Typography sx={{ fontSize: "20px" }}>
                        Continue on your mobile, scan the QR below
                    </Typography>
                    <QRCodeSVG  
                        value={`https://otg.specularo.com/${mirrorInfo._id}/indoor`} 
                        includeMargin
                        size="256"
                        level='M'
                        imageSettings={{
                            src:"https://specularo.com/favicon.ico",
                            excavate:true,
                            width: 40,
                            height: 40
                        }}
                    />
                </Box>
            }

            <Box className='floor-selection'>
                <List component={Stack} direction="row" sx={{ overflowX: "auto", overflowY: "hidden", touchAction: "auto", padding: 0}}>
                    {
                        multiFloorMapGeojson !== null && multiFloorMapGeojson.map(map => (
                            <ListItem key={map.floor} className='floor-item' onClick={() => setCurrentFloor(map.floor)} sx={ map.floor === currentFloor ? {backgroundColor: 'rgba(67, 93, 117, 0.9)', borderRadius: '12px'} : {}}>
                                Floor {map.floor}
                            </ListItem>
                        ))
                    }
                    <ListItem className='floor-item'>
                        Floor 1
                    </ListItem>
                    <ListItem className='floor-item'>
                        Floor 2
                    </ListItem>
                    <ListItem className='floor-item'>
                        Floor 3
                    </ListItem>
                    <ListItem className='floor-item'>
                        Floor 4
                    </ListItem>
                    <ListItem className='floor-item'>
                        Floor 5
                    </ListItem>
                </List>
            </Box>
            
        </GeneralMap>
    )
}

export function MapImage() {
  const { current: map } = useMap();

  if (!map.hasImage('stairs')) {
    map.loadImage('/stairs.png', (error, image) => {
      if (error) throw error;
      if (!map.hasImage('stairs')) map.addImage('stairs', image, { sdf: true });
    });
  }

  return null;
}