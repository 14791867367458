import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import SessionContext from "./SessionContext";

const PrivateRoute = ({ children }) => {
  const remoteInfo = useContext(SessionContext)

  //if (!remoteInfo || !remoteInfo._id) {
  //if (!auth.parsedUser || Date.now() >= auth.parsedUser.exp * 1000) {
    //return <Navigate to="/401" replace />;
  //}

  return children;
};

export default PrivateRoute