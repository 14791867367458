import IndoorMap from "../components/IndoorMap";

export default function IndoorMapPage() {
    return (
        <section>
            <IndoorMap language="en" viewOptions={{
                latitude: 56.46,
                longitude: -2.978,
                zoom: 19.5,
                bearing: 0,
                pitch: 45
            }} />
        </section>
    )
}