import { useRef, useEffect, useState, useCallback, useContext } from 'react';
import { AttributionControl, FullscreenControl, GeolocateControl, Layer, Map, Marker, NavigationControl, ScaleControl, Source } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import SessionContext from './SessionContext';
// added the following 6 lines.
import mapboxgl from 'mapbox-gl';
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import MenuIcon from '@mui/icons-material/Menu';
import StraightIcon from '@mui/icons-material/Straight';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TourIcon from '@mui/icons-material/Tour';
import { parkingAvailableLayerOptions, parkingRouteLineLayerOptions, parkingRouteStrokeLayerOptions, parkingTakenLayerOptions, rasterLayerOptions } from '../helpers/layerOptions';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function GeneralMap({ language = 'en', viewOptions, children, mapRef, ...rest }) {
    //const mapRef = useRef()
    const [map, setMap] = useState()

    const [viewport, setViewport] = useState({});
    const [viewState, setViewState] = useState(viewOptions);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((pos) => {
            setViewport({
                ...viewport,
                latitude: pos.coords.latitude,
                longitude: pos.coords.longitude,
                zoom: 3.5,
            });
        });
    }, []);

    useEffect(() => {
        if (map && language) {
            map.setLanguage(language) // <= HERE IS THE KEY: This sets the language.
        }
    }, [map, language])

    // Called only once map is rendered (async)
    const onRender = useCallback(() => {
        const mapElem = mapRef?.current
        if (!mapElem) return

        // Saves map reference to state
        setMap(mapElem)
    }, [])

    const onHover = useCallback(event => {
        const {
            features,
            point: { x, y }
        } = event;
        const hoveredFeature = features && features[0];

        // prettier-ignore
        setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    }, []);

    
    return (
        <div className='mapWrapper'>
            <Map
                initialViewState={viewState}
                mapStyle="mapbox://styles/ofekk/clmunhm0b02ie01nz8ies030k"
                onMove={evt => setViewState(evt.viewState)}
                //styleDiffing
                ref={mapRef}
                onRender={onRender}
                {...rest}
            >

                <NavigationControl position="bottom-left" visualizePitch />
                <GeolocateControl position="bottom-left" />
                <FullscreenControl position="bottom-left" />

                {
                    process.env.REACT_APP_BACKEND_URL.includes("localhost") && 
                    <div className="sidebar">
                        Longitude: {parseFloat(viewState.longitude).toFixed(6)} | Latitude: {parseFloat(viewState.latitude).toFixed(6)} | Zoom: {parseFloat(viewState.zoom).toFixed(1)}
                    </div>
                }

                {children}

            </Map>
        </div>
    )
}