import { useContext } from "react"
import SessionContext from "../components/SessionContext"

export default function Main() {
    const remoteInfo = useContext(SessionContext)
    return (
        <section>
            <h1>Base UI + Create React App scaffold (JavaScript)</h1>
            {
            Object.keys(remoteInfo).length > 0 &&
            <img src={`${process.env.REACT_APP_BACKEND_URL}/file/${remoteInfo.branch.company.logo}`} alt="" />
            }

            <div className="item">
            <a href="https://mui.com/base-ui/">Base UI</a> is a library of
            unstyled React UI components and hooks.
            </div>
            <div className="item">
            <a href="https://create-react-app.dev/">Create React App</a> is a framework for quickly
            creating a new React project without the need to configure complex build tools or
            development environments.
            </div>
        </section>
    )
}