import { useRef, useEffect, useState, useCallback, useContext } from 'react';
import { AttributionControl, FullscreenControl, GeolocateControl, Layer, Map, Marker, NavigationControl, ScaleControl, Source } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import SessionContext from './SessionContext';
// added the following 6 lines.
import mapboxgl from 'mapbox-gl';
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import MenuIcon from '@mui/icons-material/Menu';
import StraightIcon from '@mui/icons-material/Straight';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import TourIcon from '@mui/icons-material/Tour';
import { parkingAvailableLayerOptions, parkingRouteLineLayerOptions, parkingRouteStrokeLayerOptions, parkingTakenLayerOptions, rasterLayerOptions } from '../helpers/layerOptions';
import GeneralMap from './GeneralMap';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function ParkingMap({ language = 'en', viewOptions }) {
    const mirrorInfo = useContext(SessionContext)

    const [parkingMapGeojson, setParkingMapGeojson] = useState(null)
    const [routingMapGeojson, setRoutingMapGeojson] = useState(null)
    const [hoverInfo, setHoverInfo] = useState(null);

    const [navigating, setNavigating] = useState(false)
    const [showDirections, setShowDirections] = useState(false)


    useEffect(() => {
        if (mirrorInfo && mirrorInfo._id) {
            fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_parking_data`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mirror: {
                        token: mirrorInfo._id
                    }
                })
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setParkingMapGeojson(res)
                })
            fetch(`${process.env.REACT_APP_BACKEND_URL}/otg/get_parking_route`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    mirror: {
                        token: mirrorInfo._id
                    },
                    parkingType: "handicap_parking"
                })
            })
                .then(res => res.json())
                .then(res => {
                    console.log(res)
                    setRoutingMapGeojson({
                        pathGeojson: res.pathGeojson, directions: combineDirections(res.directions).map(item => ({
                            ...item,
                            instruction: getInstructionTranslate(item.instruction)
                        }))
                    })
                    console.log()
                })
        }
    }, [mirrorInfo])

    function getInstructionTranslate(instruction) {
        switch (instruction) {
            case 'Go straight': return "המשך קדימה"
            case 'Turn right': return 'פנה ימינה'
            case 'Turn left': return 'פנה שמאלה'
            case 'Arrive': return 'הגיע לחניה'
        }
    }

    function getDirectionIcon(instruction) {
        if (instruction.includes("קדימה")) {
            return <StraightIcon sx={{ fontSize: "40px" }} />
        }
        if (instruction.includes("ימינה")) {
            return <TurnRightIcon sx={{ fontSize: "40px" }} />
        }
        if (instruction.includes("שמאלה")) {
            return <TurnLeftIcon sx={{ fontSize: "40px" }} />
        }
        if (instruction.includes("הגיע")) {
            return <TourIcon sx={{ fontSize: "40px" }} />
        }
    }

    function combineDirections(instructions) {
        const combinedInstructions = [];
        let currentInstruction = null;
        let totalDistance = 0;

        for (const instruction of instructions) {
            if (instruction.instruction === "Go straight") {
                if (!currentInstruction) {
                    currentInstruction = instruction;
                } else {
                    currentInstruction.distance += instruction.distance;
                }
                totalDistance += instruction.distance;
            } else {
                if (currentInstruction) {
                    combinedInstructions.push(currentInstruction);
                    currentInstruction = null;
                }
                combinedInstructions.push(instruction);
            }
        }

        if (currentInstruction) {
            combinedInstructions.push(currentInstruction);
        }

        return combinedInstructions
    }

    return (
        <GeneralMap language={language} viewOptions={viewOptions}>
            <Source
                type="raster"
                name="parking_raster"
                id="parking_raster"
                url="mapbox://ofekk.79r8sfic"

            >
                <Layer {...rasterLayerOptions} />
            </Source>
            <Source type="geojson" data={parkingMapGeojson}>
                <Layer {...parkingAvailableLayerOptions} />
                <Layer {...parkingTakenLayerOptions} />
            </Source>
            <Source type="geojson" data={routingMapGeojson?.pathGeojson ? routingMapGeojson.pathGeojson : { type: 'FeatureCollection', features: [] }}>
                <Layer {...parkingRouteStrokeLayerOptions} />
                <Layer {...parkingRouteLineLayerOptions} />
            </Source>


            <Box className='directionsLeft' onClick={() => { setShowDirections(!showDirections) }}>
                <Typography sx={{ fontSize: "26px" }}>
                    <MenuIcon />
                </Typography>
            </Box>
            <Box className='directionsRight'>
                {
                    !navigating &&
                    <Typography sx={{ fontSize: "26px", display: "flex", justifyContent: "center", alignItems: "center", columnGap: "5px" }}>
                        <PlayCircleFilledWhiteIcon sx={{ fontSize: "30px" }} />
                        התחל נסיעה
                    </Typography>
                }
            </Box>
            {routingMapGeojson !== null && showDirections &&
                <Box className='directionsLeft directionsLeftSet'>
                    {
                        routingMapGeojson.directions.map((direction, i) => (
                            <div key={i} className='directionGrid'>
                                <Box dir='rtl' sx={{ gridArea: "1 / 1 / 3 / 2", display: "flex" }}>{getDirectionIcon(direction.instruction)}</Box>
                                <Box dir='rtl' sx={{ gridArea: "1 / 2 / 2 / 3" }}><Typography>{Math.ceil(direction.distance)} מטרים</Typography></Box>
                                <Box dir='rtl' sx={{ gridArea: "2 / 2 / 3 / 3" }}><Typography>{direction.instruction}</Typography></Box>
                            </div>
                        ))
                    }
                </Box>
            }
        </GeneralMap>
    )
}